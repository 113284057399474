<template>
  <div id="panelManagement">
    <CSTabBar
        :checked-tab="checkedTaBar"
        :tabbar="tabBar"
        @changeTabBar="changeTabBar"
    />
    <!-- 点击面板组管理显示内容 -->
    <template v-if="checkedTaBar == 1">
      <div class="filter-panel">
        <input
            v-model="queryGroupInfo.keyword"
            class="filter-panel-input"
            placeholder="搜索面板组名称/备注"
            type="text"
        />
        <button
            class="btn btn-primary queryBtn"
            type="button"
            @click="queryPanelGroupBtn"
        >
          查询
        </button>
      </div>

      <div class="result-panel">

        <div style="padding-left: 20px;padding-top: 20px;margin-bottom: -20px;">
          <svg
              aria-hidden="true"
              class="icon"
          >
            <use xlink:href="#icon-menua-zu92"></use>
          </svg>
          <span style="color: #999;">
            1、开启“通行限行”后，闸机（进）/楼层门禁通行需申请，经所选公司审核人审批通过后，才可扫码/刷脸通过。 <br/> &nbsp;&nbsp;&nbsp;&nbsp;
            2、关闭“通行限行”后，闸机（进）/楼层门禁可随意扫码/刷脸通过。<br/>&nbsp;&nbsp;&nbsp;&nbsp;
            3、开启“体温检测”后，闸机/楼层门禁将会开启体温检测，当体温超过指定度数，不会放行。<br/>&nbsp;&nbsp;&nbsp;&nbsp;
            4、关闭“体温检测”后，闸机/楼层门禁不会检测体温。<br/>&nbsp;&nbsp;&nbsp;&nbsp;
            5、开启“通行限行”后，闸机（出）需要扫码/刷脸通过。<br/>&nbsp;&nbsp;&nbsp;&nbsp;
            6、关闭“通行限行”后，闸机（出）只要识别到人脸，就会放行。<br/>&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </div>

        <CSTable :thead-top="60">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <div class="sticky-right">
                <button
                    class="btn btn-primary"
                    style="width: 100px; padding: 0; height: 30px"
                    @click="addPanelGroupBtn"
                >
                  <svg aria-hidden="true" class="icon">
                    <use xlink:href="#icon-menujiahao"></use>
                  </svg>
                  添加面板组
                </button>
              </div>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>添加时间</th>
              <th>面板组名称</th>
              <th>通行限行</th>
              <th>体温检测</th>
              <th>面板数量</th>
              <th>备注</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="panelGroup in panelGroupList" :key="panelGroup.id">
              <td class="date-td">
                {{ panelGroup.createTime }}
              </td>
              <td>
                {{ panelGroup.name }}
              </td>
              <td>{{ panelGroup.isRestriction ? "开启" : "关闭" }}限行</td>
              <td>{{ panelGroup.isCheckTemperature ? "开启" : "关闭" }}检测</td>
              <td>
                <span
                    class="allow-click"
                    @click="queryPanelGroupComment(panelGroup.id)"
                >
                  {{ panelGroup.deviceCount }}
                </span>
              </td>
              <td>
                <span
                    v-if="panelGroup.comment"
                    class="allow-click"
                    @click="lookGroupPanelComment(panelGroup.comment)"
                >
                  查看
                </span>
                <span v-else> - </span>
              </td>
              <td>
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul
                      class="dropdown-menu"
                      style="min-width: 120px; width: 86px"
                  >
                    <li
                        @click="
                        changeRestriction(
                          panelGroup.id,
                          panelGroup.isRestriction
                        )
                      "
                    >
                      <a style="width: 100%"
                      >{{
                          panelGroup.isRestriction ? "关闭" : "开启"
                        }}通行限行</a
                      >
                    </li>
                    <li
                        @click="
                        changeCheckTemperature(
                          panelGroup.id,
                          panelGroup.isCheckTemperature,
                          panelGroup.isRestriction
                        )
                      "
                    >
                      <a style="width: 100%"
                      >{{
                          panelGroup.isCheckTemperature ? "关闭" : "开启"
                        }}体温检测</a
                      >
                    </li>
                    <li @click="editPanelGroupBtn(panelGroup)">
                      <a style="width: 100%">修改</a>
                    </li>
                    <li @click="deletePanelGroup(panelGroup.id)">
                      <a style="width: 100%">删除</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <!-- 分页 -->
        <Pagination componentName="Pagination" name="pagination"/>
      </div>
    </template>
    <!-- 点击面板管理显示内容 -->
    <template v-if="checkedTaBar == 2">
      <div class="filter-panel">
        <input
            v-model="queryPanelInfo.keyword"
            class="filter-panel-input"
            placeholder="搜索面板编号/备注"
            style="width: 200px"
            type="text"
        />
        <CSSelect height="30px" style="margin: 0 0 0 20px">
          <select v-model="queryPanelInfo.type" class="filter-panel-select">
            <option value="">全部面板类型</option>
            <option
                v-for="panel in PanelType"
                :key="panel.id"
                :value="panel.id"
            >
              {{ panel.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect height="30px" style="margin: 0 0 0 20px">
          <select
              v-model="queryPanelInfo.buildingCode"
              class="filter-panel-select"
          >
            <option value="">全部楼栋</option>
            <option
                v-for="build in buildList"
                :key="build.id"
                :value="build.code"
            >
              {{ build.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect
            v-if="![4, 5, ''].includes(queryPanelInfo.type)"
            height="30px"
            style="margin: 0 0 0 20px"
        >
          <select class="filter-panel-select" style="color: #000">
            <option v-if="[1, 3, 6].includes(queryPanelInfo.type)" value="">
              进
            </option>
            <option v-if="queryPanelInfo.type == 2" value="">出</option>
          </select>
        </CSSelect>
        <button
            class="btn btn-primary queryBtn"
            style="margin-left: 30px"
            @click="queryPanelBtn"
        >
          查询
        </button>
      </div>
      <div class="result-panel">
        <CSTable thead-top="60px">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                  class="btn btn-primary sticky-right"
                  style="width: 90px; padding: 0; height: 30px"
                  @click="addPanelBtn"
              >
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加面板
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>添加时间</th>
              <th>面板编号</th>
              <th>面板类型</th>
              <th>所在楼栋</th>
              <th>所在楼层</th>
              <th>关联业主/租客</th>
              <th>进/出</th>
              <th>面板ID</th>
              <th>屏幕分辨率</th>
              <th>备注</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="panel in PanelList" :key="panel.id">
              <td class="date-td">{{ panel.createTime }}</td>
              <td>{{ panel.deviceNo }}</td>
              <td>{{ processingPanelType(panel.type) }}</td>
              <td>{{ panel.buildingName ? panel.buildingName : "-" }}</td>
              <td>{{ panel.floor ? panel.floor : "-" }}</td>
              <td>
                <!-- NOTO: 未添加查看 -->
                <span
                    v-if="panel.lesseeCount > 0"
                    class="allow-click"
                    @click="queryPanelRelatedTenant(panel.id)"
                >
                  {{ panel.lesseeCount + "家" }}
                </span>
                <span v-else> - </span>
              </td>
              <td>
                {{ judgeInAndOut(panel.type) }}
              </td>
              <td>{{ panel.id }}</td>
              <td>
                {{
                  panel.resolution
                      ? processingPanelResolution(panel.resolution)
                      : "-"
                }}
              </td>
              <td>
                <span
                    v-if="panel.comment"
                    class="allow-click"
                    @click="lookPanelComment(panel.comment)"
                >
                  查看
                </span>
                <span v-else> - </span>
              </td>
              <td>
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul
                      class="dropdown-menu"
                      style="min-width: 76px; width: 86px"
                  >
                    <li @click="editPanelBtn(panel)">
                      <a style="width: 100%">修改</a>
                    </li>
                    <li @click="deletePanelBtn(panel)">
                      <a style="width: 100%">删除</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <!-- 分页 -->
        <Pagination componentName="Pagination" name="pagination"/>
      </div>
    </template>
    <template v-if="checkedTaBar == 3">
      <RestartPanel></RestartPanel>
    </template>
    <!-- 添加面板组 -->
    <CSDialog
        :dialogTitle="
        addPanelGroupOrEditPanelGroup == 1 ? '添加面板组' : '修改面板组'
      "
        :dialogVisible="addPanelGroupVisible"
        dialogWidth="945px"
        @onClose="addPanelGroupDialogClose"
        @onConfirm="addEditPanelGroup"
    >
      <div slot="dialog-content" style="padding: 30px">
        <p style="font-size: 20px; color: #999; padding-left: 10px">
          <svg aria-hidden="true" class="icon">
            <use xlink:href="#icon-menua-zu92"></use>
          </svg>
          同一个组中的面板屏幕分辨率必须一样。
        </p>
        <div class="notice_box">
          <div class="notice_title" style="width: 120px">面板组名称</div>
          <input
              v-model="addPanelGroupInfo.name"
              class="notice_name"
              maxlength="20"
              placeholder="限20个字"
              type="text"
          />
        </div>
        <div class="notice_box" style="margin: 26px 0 0 0">
          <div class="notice_title" style="vertical-align: top; width: 120px">
            面板
          </div>
          <div style="display: inline-block">
            <div
                style="
                width: 398px;
                background-color: #f0f0f0;
                border: 1px solid #f0f0f0;
                border-radius: 4px;
                height: 198px;
                font-size: 24px;
                resize: none;
                padding: 5px 10px 5px 10px;
                margin-bottom: 20px;
                overflow-y: auto;
              "
            >
              <div
                  v-for="(name, index) in panelName"
                  :key="index"
                  style="font-size: 24px; margin-left: 10px; position: relative"
              >
                {{ name }}
                <svg
                    aria-hidden="true"
                    class="icon"
                    style="margin-left: 20px"
                    @click="deletepanelName(index)"
                >
                  <use xlink:href="#icon-menushanchu"></use>
                </svg>
              </div>
            </div>
            <div>
              <button
                  class="btn btn-primary"
                  style="
                  width: 100px;
                  padding: 0 0 0 15px;
                  height: 40px;
                  font-size: 24px;
                  position: relative;
                "
                  @click="PanelsInPanelGroupsBtn"
              >
                <svg
                    aria-hidden="true"
                    class="icon"
                    style="
                    height: 18px;
                    width: 18px;
                    position: absolute;
                    top: 50%;
                    left: 10%;
                    transform: translateY(-50%);
                  "
                >
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加
              </button>
            </div>
          </div>
        </div>
        <div class="notice_box" style="margin: 26px 0 0 0">
          <div class="notice_title" style="vertical-align: top; width: 120px">
            备注
          </div>
          <div style="display: inline-block">
            <textarea
                v-model="addPanelGroupInfo.comment"
                maxlength="50"
                placeholder="限50个字"
                style="
                width: 718px;
                height: 198px;
                background-color: #f0f0f0;
                border: 1px solid #f0f0f0;
                border-radius: 4px;
                height: 198px;
                font-size: 24px;
                resize: none;
                padding-left: 10px;
              "
                type="number"
            ></textarea>
          </div>
        </div>
      </div>
    </CSDialog>
    <!-- 添加面板 -->
    <CSDialog
        :dialogTitle="addPanelOrEditPanel == 1 ? '添加面板' : '修改面板'"
        :dialogVisible="addPanelVisible"
        dialogWidth="964px"
        @click="
        tenantVisible = false;
        buildingRoomVisible = false;
      "
        @onClose="addPanelDialogClose"
        @onConfirm="addEidtPanel"
    >
      <div slot="dialog-content" style="padding: 30px">
        <div class="notice_box">
          <div class="notice_title">面板编号</div>
          <input
              v-model="addPanelInfo.deviceNo"
              class="notice_name"
              maxlength="32"
              placeholder="限32位"
              type="text"
          />
        </div>
        <div class="notice_box">
          <div class="notice_title">面板类型</div>
          <CSSelect height="40px" iWidth="36px">
            <select v-model="addPanelInfo.type" class="notice_select" style="color: #999999">
              <option value="">请选择</option>
              <option v-for="type in PanelType" :key="type.id" :value="type.id">
                {{ type.name }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div v-if="addPanelInfo.type != 3" class="notice_box">
          <div class="notice_title">所在楼栋</div>
          <CSSelect height="40px" iWidth="36px">
            <select
                v-model="addPanelInfo.buildingCode"
                class="notice_select"
                style="color: #999999"
                @change="changeBuilding"
            >
              <option value="">请选择</option>
              <option
                  v-for="build in buildList"
                  :key="build.id"
                  :value="build.code"
              >
                {{ build.name }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div v-if="addPanelInfo.type != 3" class="notice_box">
          <div class="notice_title">所在楼层</div>
          <CSSelect height="40px" i-width="36px">
            <select v-model="addPanelInfo.floor" class="notice_select" style="color: #999999">
              <option value="">请选择</option>
              <option v-for="floor in floorSpace" :key="floor" :value="floor">
                {{ floor }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div v-if="addPanelInfo.type == 3" class="notice_box">
          <div class="notice_title">所在楼栋房间</div>
          <div
              style="position: relative; display: inline-block; z-index: 100"
              @click.stop="buildingRoomVisible = !buildingRoomVisible"
          >
            <CSSelect height="40px" iWidth="36px">
              <div
                  v-if="buildingRoomName == ''"
                  style="
                  font-size: 24px;
                  color: #999;
                  margin-left: 10px;
                  width: 179px;
                "
              >
                请选择
              </div>
              <div
                  v-else
                  style="
                  font-size: 24px;
                  margin-left: 10px;
                  width: 179px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ buildingRoomName }}
              </div>
              <TreePanel v-show="buildingRoomVisible" width="320px">
                <div style="margin-bottom: 10px">
                  <input
                      v-model="roomNo"
                      placeholder="请填写房间编号"
                      style="
                      width: 200px;
                      height: 40px;
                      padding-left: 10px;
                      font-size: 20px;
                      border: 1px solid #999;
                      border-radius: 4px;
                      vertical-align: middle;
                    "
                      type="text"
                  />
                  <button
                      class="btn btn-primary"
                      style="
                      margin-left: 15px;
                      padding: 0;
                      vertical-align: middle;
                      width: 70px;
                      height: 40px;
                      font-size: 20px;
                    "
                      type="button"
                      @click="queryBuildRoom()"
                  >
                    查询
                  </button>
                </div>
                <CSTree :tree="buildRoomList" @change="addBuildingRoom"/>
              </TreePanel>
            </CSSelect>
          </div>
        </div>
        <div v-if="addPanelInfo.type == 6" class="notice_box">
          <div class="notice_title" style="vertical-align: top">业主/租客</div>
          <div style="position: relative; display: inline-block; z-index: 100">
            <CSSelect height="40px" iWidth="36px" style="margin-bottom: 10px">
              <div
                  style="
                  font-size: 24px;
                  color: #999;
                  margin-left: 10px;
                  width: 179px;
                "
                  @click.stop="tenantVisible = !tenantVisible"
              >
                请选择
              </div>
              <TreePanel v-show="tenantVisible" width="310px">
                <div>
                  <input
                      v-model="keyword"
                      placeholder="搜索"
                      style="
                      width: 200px;
                      height: 40px;
                      padding-left: 10px;
                      font-size: 20px;
                      border: 1px solid #999;
                      border-radius: 4px;
                      vertical-align: middle;
                    "
                      type="text"
                  />
                  <button
                      class="btn btn-primary"
                      style="
                      margin-left: 10px;
                      width: 70px;
                      height: 40px;
                      font-size: 24px;
                      padding: 0 0 2px 0;
                      vertical-align: middle;
                    "
                      type="button"
                      @click="queryTenant"
                  >
                    查询
                  </button>
                </div>
                <div style="overflow-y: auto; font-size: 20px">
                  <div
                      v-for="lessee in lesseeList"
                      :key="lessee.id"
                      style="border-bottom: 1px solid #f0f0f0"
                      @click="addCompanyName(lessee.companyName, lessee.id)"
                  >
                    <div style="margin: 5px 10px">{{ lessee.companyName }}</div>
                    <div style="margin: 5px 10px">
                      <span>{{ lessee.deputyName }}</span>
                      <span>{{ " 【" + lessee.deputyPhone + "】" }}</span>
                    </div>
                  </div>
                </div>
              </TreePanel>
            </CSSelect>
            <div v-if="addPanelInfo.lesseeList.length > 0">
              <div
                  v-for="(companyName, index) in companyNameList"
                  :key="index"
                  style="font-size: 24px; position: relative; padding: 5px 0"
              >
                {{ companyName }}
                <svg
                    aria-hidden="true"
                    class="icon"
                    style="margin-left: 20px"
                    @click="removeCompanyName(index)"
                >
                  <use xlink:href="#icon-menushanchu"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div v-if="![4, 5, ''].includes(addPanelInfo.type)" class="notice_box">
          <div class="notice_title">进/出</div>
          <CSSelect height="40px" iWidth="36px">
            <select class="notice_select" style="color: #000">
              <option v-if="[1, 3, 6].includes(addPanelInfo.type)" value="">
                进
              </option>
              <option v-if="addPanelInfo.type == 2" value="">出</option>
            </select>
          </CSSelect>
        </div>
        <div class="notice_box">
          <div class="notice_title">面板分辨率</div>
          <CSSelect height="40px" iWidth="36px">
            <select v-model="addPanelInfo.resolution" class="notice_select" style="color: #999999">
              <option value="">请选择</option>
              <option
                  v-for="resolvingPower in resolvingPower"
                  :key="resolvingPower.id"
                  :value="resolvingPower.id"
              >
                {{ resolvingPower.name }}
              </option>
            </select>
          </CSSelect>
        </div>
        <div class="notice_box" style="margin: 26px 0 0 0">
          <div class="notice_title" style="vertical-align: top">备注</div>
          <div style="width: 540px; display: inline-block">
            <textarea
                v-model="addPanelInfo.comment"
                maxlength="50"
                placeholder="限50个字"
                style="
                width: 718px;
                background-color: #f0f0f0;
                border: 1px solid #f0f0f0;
                border-radius: 4px;
                height: 198px;
                font-size: 24px;
                resize: none;
                padding-left: 10px;
              "
                type="number"
            ></textarea>
          </div>
        </div>
      </div>
    </CSDialog>
    <!-- 添加面板组里的选择面板 -->
    <CSDialog
        :dialogVisible="addPanelsInPanelGroupsVisible"
        dialogTitle="选择面板"
        dialogWidth="1096px"
        @onClose="addPanelsInPanelGroupsClose"
        @onConfirm="addPanelId"
    >
      <div slot="dialog-content" style="padding: 30px">
        <input
            v-model="queryPanelGroupPanelInfo.keyword"
            placeholder="搜索面板编号/备注"
            style="
            vertical-align: middle;
            padding-left: 10px;
            width: 180px;
            height: 30px;
            border: 1px solid #979797;
            border-radius: 1px;
            margin: 0 20px 0 5px;
            font-size: 14px;
          "
            type="text"
        />
        <CSSelect height="30px" style="margin-right: 20px">
          <select
              v-model="queryPanelGroupPanelInfo.type"
              style="width: 148px; vertical-align: middle; font-size: 14px"
          >
            <option value="">请选择面板类型</option>
            <option
                v-for="panel in PanelType"
                :key="panel.id"
                :value="panel.id"
            >
              {{ panel.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect height="30px" style="margin-right: 20px">
          <select
              v-model="queryPanelGroupPanelInfo.buildingCode"
              style="width: 148px; vertical-align: middle; font-size: 14px"
          >
            <option value="">请选择楼栋</option>
            <option
                v-for="build in buildList"
                :key="build.id"
                :value="build.code"
            >
              {{ build.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect
            v-if="![4, 5, ''].includes(queryPanelGroupPanelInfo.type)"
            height="30px"
            style="margin: 0 20px 0 0"
        >
          <select class="filter-panel-select" style="color: #000">
            <option
                v-if="[1, 3, 6].includes(queryPanelGroupPanelInfo.type)"
                value=""
            >
              进
            </option>
            <option v-if="queryPanelGroupPanelInfo.type == 2" value="">
              出
            </option>
          </select>
        </CSSelect>
        <button
            class="btn btn-primary"
            style="padding: 0; width: 50px; height: 30px"
            @click="queryPanelGroupPanelBtn"
        >
          查询
        </button>
        <div
            style="
            height: 270px;
            width: 1035px;
            overflow-y: auto;
            margin-top: 20px;
          "
        >
          <table
              border="1"
              class="
              footable
              table table-stripped
              toggle-arrow-tiny
              modal-inner-table
            "
              style="width: 1020px; margin: 0 0 20px 1px; font-size: 20px"
          >
            <thead>
            <tr class="head_row">
              <th class="whilt" style="width: 95px">
                <input
                    style="vertical-align: middle"
                    type="checkbox"
                    @change="selectAll()"
                />
                <span style="vertical-align: middle; margin-left: 10px"
                >全选</span
                >
              </th>
              <th style="width: 130px">面板编号</th>
              <th style="width: 140px">面板类型</th>
              <th style="width: 135px">所在楼栋</th>
              <th style="width: 120px">所在楼层</th>
              <th style="width: 90px">进/出</th>
              <th style="width: 140px">屏幕分辨率</th>
              <th style="width: 255px">备注</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(panel, index) in panelGroupPanelList"
                :key="panel.id"
                class="head_row"
            >
              <td class="center">
                <input
                    :checked="panel.checked"
                    type="checkbox"
                    @change="panelChecked(index)"
                />
              </td>
              <td class="center">
                {{ panel.deviceNo }}
              </td>
              <td class="center">
                {{ processingPanelType(panel.type) }}
              </td>
              <td class="center">
                {{ panel.buildingName }}
              </td>
              <td class="center">
                {{ panel.floor }}
              </td>
              <td class="center">
                {{ judgeInAndOut(panel.type) }}
              </td>
              <td class="center">
                {{ processingPanelResolution(panel.resolution) }}
              </td>
              <td class="center">
                {{ panel.comment ? panel.comment : "-" }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CSDialog>
    <!-- 面板查看关联租客对话框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="panelRelatedTenantVisible"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="关联业主/租客"
        dialogWidth="430px"
        @onClose="panelRelatedTenantVisible = false"
    >
      <div
          slot="dialog-content"
          style="padding: 30px; font-size: 24px; text-align: center"
      >
        <div
            v-for="relatedTenant in panelRelatedTenant"
            :key="relatedTenant.id"
            style="margin-bottom: 8px"
        >
          {{ relatedTenant.companyName }}
        </div>
      </div>
    </CSDialog>
    <!-- 面板组查看备注对话框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="panelGroupDialogVisible"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="查看备注"
        dialogWidth="430px"
        @onClose="panelGroupDialogVisible = false"
    >
      <div
          slot="dialog-content"
          style="padding: 30px; font-size: 24px; text-align: center"
      >
        {{ lookGroupPanelCommentInfo }}
      </div>
    </CSDialog>
    <!-- 面板查看备注对话框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="panelDialogVisible"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="查看备注"
        dialogWidth="430px"
        @onClose="panelDialogVisible = false"
    >
      <div
          slot="dialog-content"
          style="padding: 30px; font-size: 24px; text-align: center"
      >
        {{ lookPanelCommentInfo }}
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import TreePanel from "@/components/common/TreePanel";
import Pagination from "@/components/Pagination";
import CSTree from "@/components/common/CSTree";
import RestartPanel from "@/components/RestartPanel";
import CSTabBar from "@/components/common/CSTabBar";
import {
  addDevice,
  addGroup,
  deleteDevice,
  deleteGroup,
  editDevice,
  editGroup,
  queryBuildingUrl,
  queryDevice,
  queryDeviceLessees,
  queryGroup,
  queryGroupDevices,
  queryRoomRentUrl,
  queryTenantUrl,
  updateCheckTemperatureGroupDevicesUrl,
  updateRestrictionGroupDevicesUrl
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
  name: "PanelManagement",
  components: {
    CSTabBar,
    CSTable,
    CSSelect,
    CSDialog,
    TreePanel,
    Pagination,
    CSTree,
    RestartPanel,
  },
  data() {
    return {
      tabBar: {
        1: "面板组管理",
        2: "面板管理",
        3: "控制面板"
      },
      checkedTaBar: 1,
      floorSpace: [], // 所选楼栋的楼层范围
      // 按钮切换状态
      btnState: 1,
      // 判断面板的添加或修改
      addPanelOrEditPanel: "",
      // 判断面板组的添加或修改
      addPanelGroupOrEditPanelGroup: "",
      // 添加面板组显隐
      addPanelGroupVisible: false,
      // 添加面板显隐
      addPanelVisible: false,
      // 添加面板组里的面板显隐
      addPanelsInPanelGroupsVisible: false,
      // 租客下拉框显隐
      tenantVisible: false,
      // 所在楼栋房间显隐
      buildingRoomVisible: false,
      // 面板组查看备注显隐
      panelGroupDialogVisible: false,
      // 面板查看备注显隐
      panelDialogVisible: false,
      // 面板查看关联租客显隐
      panelRelatedTenantVisible: false,
      // 楼栋列表
      buildList: [],
      // 楼栋房间列表
      buildRoomList: [],
      // 租客列表
      lesseeList: [],
      // 面板列表
      PanelList: [],
      // 面板组里的面板列表
      panelGroupPanelList: [],
      // 租客公司名数组
      companyNameList: [],
      // 面板中的关联租客
      panelRelatedTenant: [],
      // 搜索租客名称
      keyword: "",
      // 楼栋房间
      buildingRoomName: "",
      // 存储全选的状态
      checked: false,
      // 存储选中的面板名称
      panelName: [],
      // 存储选中的面板分辨率id
      resolvingPowerId: [],
      // 面板组列表
      panelGroupList: [],
      // 查看面板组备注的信息
      lookGroupPanelCommentInfo: "",
      // 查看面板备注的信息
      lookPanelCommentInfo: "",
      // 添加面板信息
      addPanelInfo: {
        regionCode: this.$vc.getCurrentRegion().code, //园区编码，必填，18位
        deviceNo: "", //设备编码，必填，1-32位
        type: "", //设备类型：1：通行闸机园区入；2：通行闸机出；3：会议室入；4：广告面板；5：员工考勤面板；6：通行闸机楼栋入
        buildingCode: "", //楼栋编码，选填，26位,
        floor: "", //所在楼层，选填,
        roomId: "", //所在房间ID，选填
        resolution: "", //分辨率，必填，1:800x1280
        comment: "", //备注，选填，最多200字,
        lesseeList: [], //关联租客ID，选填
      },
      // 查询面板信息
      queryPanelInfo: {
        regionCode: this.$vc.getCurrentRegion().code, //园区编码，必填
        keyword: "", //查询关键词，选填
        type: "", //面板类型，选填
        buildingCode: "", //楼栋编码，选填
        pageNo: 1, //页码，选填
        pageSize: 10, //每页条目数量，选填
      },
      // 添加面板组信息
      addPanelGroupInfo: {
        regionCode: this.$vc.getCurrentRegion().code, //园区编码，必填，18字,
        name: "", //面板组名称，必填，1-20字
        deviceIds: [], //面板组关联设备ID，必填
        comment: "", //备注，选填
      },
      // 添加面板组里的查询面板信息
      queryPanelGroupPanelInfo: {
        regionCode: this.$vc.getCurrentRegion().code, //园区编码，必填
        keyword: "", //查询关键词，选填
        type: "", //面板类型，选填
        buildingCode: "", //楼栋编码，选填
        pageNo: 1, //页码，选填
        pageSize: 10, //每页条目数量，选填
      },
      // 查询面板组信息
      queryGroupInfo: {
        regionCode: this.$vc.getCurrentRegion().code, //园区编码，必填，18字
        keyword: "", //查询关键字，选填
        pageNo: 1, // 页码，选填
        pageSize: 10, // 每页条目，选填
      },
      // 面板类型
      PanelType: [
        {
          id: 1,
          name: "闸机(进)",
        },
        {
          id: 2,
          name: "闸机(出)",
        },
        {
          id: 3,
          name: "会议室",
        },
        {
          id: 4,
          name: "墙面广告面板",
        },
        {
          id: 5,
          name: "员工考勤面板",
        },
        {
          id: 6,
          name: "楼层门禁",
        },
      ],
      // 房间号
      roomNo: "",
      resolvingPowerList: [
        {
          id: 1,
          name: "800 * 1280",
        },
        {
          id: 2,
          name: "1920 * 1080",
        },
      ],
    };
  },
  created() {
    window.addEventListener("keydown", this.queryPanelGroupBtnDown);
    // 获取楼栋信息
    this.queryBuilding();
    // 查询租客信息
    this.queryTenant();
    // 查询面板列表
    this.queryPanelStart();
    // 查询楼栋房间
    this.queryBuildRoom();
    // 获取面板组列表
    this.getPanelGroup();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryPanelGroupBtnDown);
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination", "page_event", (page) => {
      if (this.checkedTaBar == 1) {
        this.queryGroupInfo.pageNo = page;
        this.queryPanelGroup(page);
      } else {
        this.queryPanelInfo.pageNo = page;
        this.queryPanel(page);
      }
    });
  },
  computed: {
    resolvingPower() {
      if (this.addPanelInfo.type == 4) {
        return [
          {
            id: 1,
            name: "800 * 1280",
          },
          {
            id: 2,
            name: "1920 * 1080",
          },
        ];
      } else {
        return [
          {
            id: 1,
            name: "800 * 1280",
          },
        ];
      }
    },
  },
  methods: {
    changeTabBar(index) {
      if (this.checkedTaBar == +index) {
        return;
      }
      this.checkedTaBar = +index;
      if (this.checkedTaBar == 1) {
        this.queryPanelGroup();
      } else {
        this.queryPanel();
      }
    },
    queryPanelGroupBtnDown(e) {
      if (e.keyCode !== 13) {
        return;
      }
      const refreshHandler = {
        1: this.queryPanelGroupBtn,
        2: this.queryPanelBtn,
      };
      refreshHandler[this.btnState]();
    },
    /**
     * 修改选中的楼栋
     * @param {Object} e
     * */
    changeBuilding(e) {
      if (e.target.selectedIndex > 0 || e > 0) {
        const building = this.buildList[e.target.selectedIndex - 1];
        let floorSpace = [];
        let undergroundFloor = building.undergroundFloor === 'undefined' ? 1 : -building.undergroundFloor
        if (isNaN(undergroundFloor)) {
          undergroundFloor = 1
        }
        for (
            let i = undergroundFloor;
            i <= building.aboveGroundFloor;
            i++
        ) {
          if (i !== 0) {
            floorSpace.push(i);
          }
        }
        this.floorSpace = floorSpace;
        return;
      }
      this.floorSpace = [];
    },
    // 查询楼栋
    queryBuilding() {
      this.$fly
          .post(queryBuildingUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            state: 1,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.buildList = res.data.datas;
          });
    },
    // 查询租客
    queryTenant() {
      this.$fly
          .post(queryTenantUrl, {
            regionId: this.$vc.getCurrentRegion().code,
            keyword: this.keyword,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.lesseeList = res.data.datas;
          });
    },
    // 查询楼栋房间
    queryBuildRoom(pageNo = 1, pageSize = 50) {
      this.$fly
          .post(queryRoomRentUrl, {
            regionId: this.$vc.getCurrentRegion().code,
            roomNo: this.roomNo,
            state: 1,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.buildRoomList = this.extractBuildRoom(res.data.datas);
          });
    },
    // 提取楼栋及房间号的方法
    extractBuildRoom(buildRoomList) {
      return buildRoomList.map((itemBuild) => {
        itemBuild.id = itemBuild.buildingId;
        itemBuild.name = itemBuild.buildingName;
        itemBuild.showCheckbox = false;
        itemBuild.children = itemBuild.floors.map((itemFloor, floorIndex) => {
          itemFloor.showCheckbox = false;
          itemFloor.id = floorIndex;
          itemFloor.name = itemFloor.floor;
          itemFloor.children = itemFloor.rooms.map((itemRoom) => {
            return {
              buildingId: itemBuild.buildingId,
              floor: itemFloor.floor,
              buildingName: itemBuild.buildingName,
              roomId: itemRoom.roomId,
              roomNo: itemRoom.roomNo,
              name: itemRoom.roomNo,
              checked: itemRoom.roomId === this.addPanelInfo.roomId,
              allowCheck:
                  this.addPanelInfo.roomId === undefined ||
                  this.addPanelInfo.roomId !== itemRoom.roomId,
            };
          });
          return itemFloor;
        });
        return itemBuild;
      });
    },
    // 赋值buildingCode
    addBuildingRoom(checkBoxInfo) {
      const {key, source} = checkBoxInfo,
          checkbox = source[key];
      const {buildingId, buildingName, roomNo, floor, roomId} = checkbox;
      this.addPanelInfo.buildingCode = buildingId;
      this.addPanelInfo.roomId = roomId;
      this.addPanelInfo.floor = floor;
      this.buildingRoomName = buildingName + roomNo + "号";
      this.buildingRoomVisible = false;
      this.queryBuildRoom();
    },
    // 点击下拉框选中
    addCompanyName(companyName, lessedId) {
      if (this.addPanelInfo.lesseeList.includes(lessedId)) {
        this.$vc.toast("请不要重复选中");
        return;
      }
      this.companyNameList.push(companyName);
      this.addPanelInfo.lesseeList.push(lessedId);
      this.tenantVisible = false;
    },
    // 移除数组中的公司名及租客中的id
    removeCompanyName(index) {
      this.companyNameList.splice(index, 1);
      this.addPanelInfo.lesseeList.splice(index, 1);
    },
    // 添加面板对话框Close事件
    addPanelDialogClose() {
      this.addPanelVisible = false;
      this.tenantVisible = false;
      this.buildingRoomVisible = false;
      this.buildingRoomName = "";
      this.keyword = "";
      this.roomNo = "";
      this.addPanelInfo = {
        regionCode: this.$vc.getCurrentRegion().code,
        deviceNo: "",
        type: "",
        buildingCode: "",
        floor: "",
        roomId: "",
        resolution: "",
        comment: "",
        lesseeList: [],
      };
      this.queryBuildRoom();
    },
    // 添加面板组对话框Close事件
    addPanelGroupDialogClose() {
      this.addPanelGroupVisible = false;
      this.addPanelGroupInfo = {
        regionCode: this.$vc.getCurrentRegion().code,
        name: "",
        deviceIds: [],
        comment: "",
      };
      this.panelName = [];
      this.panelGroupPanelList.forEach((item) => {
        this.$set(item, "checked", false);
      });
      this.checked = false;
    },
    // 添加面板组里的面板对话框Close事件
    addPanelsInPanelGroupsClose() {
      console.log(2222)
      this.addPanelsInPanelGroupsVisible = false;
      this.queryPanelGroupPanelInfo = {
        regionCode: this.$vc.getCurrentRegion().code,
        keyword: "",
        type: "",
        buildingCode: "",
        pageNo: 1,
        pageSize: 10,
      };
      this.checked = false;
    },
    // 全选事件
    selectAll() {
      if (!this.checked) {
        this.addPanelGroupInfo.deviceIds = [];
        this.panelName = [];
        this.panelGroupPanelList.forEach((item) => {
          this.$set(item, "checked", true);
          this.panelName.push(item.deviceNo);
          this.addPanelGroupInfo.deviceIds.push(item.id);
          this.resolvingPowerId.push(item.resolution);
        });
        this.checked = true;
      } else {
        this.panelGroupPanelList.forEach((item) => {
          this.$set(item, "checked", false);
          this.panelName = [];
          this.addPanelGroupInfo.deviceIds = [];
          this.resolvingPowerId = [];
        });
        this.checked = false;
      }
    },
    // checkbox点击事件
    panelChecked(index) {
      this.$set(
          this.panelGroupPanelList[index],
          "checked",
          !this.panelGroupPanelList[index].checked
      );
      if (this.panelGroupPanelList[index].checked) {
        this.addPanelGroupInfo.deviceIds.push(
            this.panelGroupPanelList[index].id
        );
        this.panelName.push(this.panelGroupPanelList[index].deviceNo);
        this.resolvingPowerId.push(this.panelGroupPanelList[index].resolution);
      } else {
        let deviceIdIndex = this.addPanelGroupInfo.deviceIds.indexOf(
            this.panelGroupPanelList[index].id
        );
        this.addPanelGroupInfo.deviceIds.splice(deviceIdIndex, 1);
        this.panelName.splice(deviceIdIndex, 1);
        this.resolvingPowerId.splice(deviceIdIndex, 1);
      }
    },
    // 面板组里的小×事件
    deletepanelName(index) {
      let Id = this.addPanelGroupInfo.deviceIds[index];
      this.panelName.splice(index, 1);
      this.addPanelGroupInfo.deviceIds.splice(index, 1);
      this.resolvingPowerId.splice(index, 1);
      this.panelGroupPanelList.forEach((item) => {
        if (item.id == Id) {
          this.$set(item, "checked", false);
        }
      });
    },
    // 添加面板组里的添加面板对话框确认事件
    addPanelId() {
      this.addPanelsInPanelGroupsVisible = false;
    },
    // 初始获取面板组列表
    getPanelGroup(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(queryGroup, {
            regionCode: this.$vc.getCurrentRegion().code, //园区编码，必填，18字
            pageNo: 1, //页码，选填
            pageSize: 10, //每页条目，选填
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.length,
                currentPage: pageNo,
                pageSize: res.data.total ? pageSize : res.data.datas.length,
              });
            }
            this.panelGroupList = res.data.datas;
          });
    },
    // changeTabBar(state) {
    //   this.btnState = state;
    //   if (state == 1) {
    //     this.queryPanelGroup();
    //   } else {
    //     this.queryPanel();
    //   }
    // },
    // 查询面板组按钮
    queryPanelGroupBtn() {
      this.queryPanelGroup();
    },
    // 查询面板组列表方法
    queryPanelGroup(pageNo = this.queryGroupInfo.pageNo, pageSize = 10) {
      this.$fly
          .post(queryGroup, {
            ...this.queryGroupInfo,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.panelGroupList = res.data.datas;
          });
    },
    // 添加面板组按钮
    addPanelGroupBtn() {
      this.addPanelGroupOrEditPanelGroup = 1;
      this.addPanelGroupInfo = {
        regionCode: this.$vc.getCurrentRegion().code,
        name: "",
        deviceIds: [],
        comment: "",
      };
      this.panelName = [];
      this.addPanelGroupVisible = true;
      this.queryPanelGroupPanelBtn();
    },
    changeRestriction(id, isRestriction) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定${isRestriction ? "关闭" : "开启"}限行吗？`,
        onConfirm: () => {
          this.$fly
              .post(updateRestrictionGroupDevicesUrl, {
                id,
                isRestriction: Number(!isRestriction),
                regionCode: this.$vc.getCurrentRegion().code,
              })
              .then((res) => {
                if (res.code === 0) {
                  this.$vc.toast(
                      `${isRestriction ? "关闭" : "开启"}授权限行成功`
                  );
                  this.$CSDialog.instance.closeDialog();
                  this.queryPanelGroup();
                }
              });
        },
      });
    },
    // 体温检测
    changeCheckTemperature(id, isCheckTemperature, isRestriction) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定${isCheckTemperature ? "关闭" : "开启"}体温检测吗？`,
        onConfirm: () => {
          this.$fly
              .post(updateCheckTemperatureGroupDevicesUrl, {
                id,
                isRestriction: Number(isRestriction),
                isCheckTemperature: Number(!isCheckTemperature),
                regionCode: this.$vc.getCurrentRegion().code,
              })
              .then((res) => {
                if (res.code === 0) {
                  this.$vc.toast(
                      `${isCheckTemperature ? "关闭" : "开启"}体温检测成功`
                  );
                  this.$CSDialog.instance.closeDialog();
                  this.queryPanelGroup();
                }
              });
        },
      });
    },
    // 修改面板组按钮
    editPanelGroupBtn(panelGroup) {

      this.addPanelGroupVisible = true;

      this.queryPanelGroupPanelBtn();
      this.addPanelGroupOrEditPanelGroup = 2;
      this.addPanelGroupInfo = {
        id: panelGroup.id,
        regionCode: this.$vc.getCurrentRegion().code,
        name: panelGroup.name,
        deviceIds: [],
        comment: panelGroup.comment,
      };
      this.$fly
          .get(queryGroupDevices, {
            groupId: panelGroup.id,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }

            this.panelName = [];
            this.panelGroupPanelList.push(...res.data);
            setTimeout(() => {
              res.data.forEach((item) => {
                this.panelName.push(item.deviceNo);
                this.addPanelGroupInfo.deviceIds.push(item.id);
                this.panelGroupPanelList.forEach((panelGroupPanel) => {
                  if (panelGroupPanel.id === item.id) {
                    this.$set(panelGroupPanel, "checked", true);
                  }
                });
              })
            }, 100);
          });
      this.addPanelGroupVisible = true;
    },


    // 修改添加面板组的方法
    addEditPanelGroup() {
      let powerId = this.resolvingPowerId[0];
      this.resolvingPowerId.forEach((item) => {
        if (item != powerId && this.addPanelGroupInfo.name !== null && this.addPanelGroupInfo.name !== '') {
          this.$vc.toast("请选择分辨率一样的面板");
          return;
        }
      });
      if (this.addPanelGroupOrEditPanelGroup == 1) {
        this.$fly.post(addGroup, this.addPanelGroupInfo).then((res) => {
          if (res.code != 0) {
            this.$vc.toast("添加失败");
            return;
          }
          this.$vc.toast("添加成功");
          this.addPanelGroupVisible = false;
          this.getPanelGroup();
        });
      } else {
        this.$fly.post(editGroup, this.addPanelGroupInfo).then((res) => {
          if (res.code != 0) {
            this.$vc.toast("修改失败");
            return;
          }
          this.$vc.toast("修改成功");
          this.addPanelGroupVisible = false;
          this.getPanelGroup();
        });
      }
    },
    // 删除面板组按钮
    deletePanelGroup(groupId) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确认删除吗?",
        onConfirm: () => {
          this.$fly
              .post(deleteGroup, {
                regionCode: this.$vc.getCurrentRegion().code, //园区编码，必填，18字
                groupId, //面板组ID，必填
              })
              .then((res) => {
                if (res.code != 0) {
                  this.$vc.toast("删除失败");
                  return;
                }
                this.$vc.toast("删除成功");
                this.$CSDialog.instance.closeDialog();
                this.getPanelGroup();
              });
        },
      });
    },
    // 初始查询面板列表
    queryPanelStart(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(queryDevice, {
            regionCode: this.$vc.getCurrentRegion().code,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagintion", "init", {
                total: res.data.total || res.data.datas.length,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.PanelList = res.data.datas;
            this.panelGroupPanelList = res.data.datas;
            this.panelGroupPanelList.forEach((item, index) => {
              this.$set(this.panelGroupPanelList[index], "checked", false);
            });
          });
    },
    // 查询面板按钮
    queryPanelBtn() {
      this.queryPanel();
    },
    queryPanel(pageNo = this.queryPanelInfo.pageNo, pageSize = 10) {
      this.$fly
          .post(queryDevice, {
            ...this.queryPanelInfo,
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
            this.PanelList = res.data.datas;
          });
    },
    // 面板租里的查询面板按钮
    queryPanelGroupPanelBtn() {
      this.$fly
          .post(queryDevice, {
            ...this.queryPanelGroupPanelInfo,
            groupId: -1,
            pageSize: 50,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.panelName = [];
            this.panelGroupPanelList.forEach((item) => {
              this.$set(item, "checked", false);
            });
            this.PanelList = res.data.datas;
            this.panelGroupPanelList = res.data.datas;
          });
    },
    // 添加面板按钮
    addPanelBtn() {
      this.addPanelOrEditPanel = 1;
      this.addPanelVisible = true;
    },
    // 修改面板按钮
    editPanelBtn(panel) {
      console.log(this.buildList, 2222222222222222);
      let selectIndex = "";
      this.addPanelOrEditPanel = 2;
      this.buildingRoomName = panel.buildingName + panel.roomNo + "号";
      this.addPanelInfo = {
        id: panel.id,
        regionCode: panel.regionCode,
        deviceNo: panel.deviceNo,
        type: panel.type,
        buildingCode: panel.buildingCode,
        floor: panel.floor,
        roomId: panel.roomId,
        resolution: panel.resolution,
        comment: panel.comment,
        lesseeList: [],
      };
      this.buildList.forEach((item, index) => {
        if (item.code === this.addPanelInfo.buildingCode) {
          selectIndex = index;
        }
      });
      if (selectIndex) {
        const building = this.buildList[selectIndex];
        let floorSpace = [];
        for (
            let i = -building.undergroundFloor;
            i <= building.aboveGroundFloor;
            i++
        ) {
          if (i !== 0) {
            floorSpace.push(i);
          }
        }
        this.floorSpace = floorSpace;
      }
      this.$fly
          .get(queryDeviceLessees, {
            deviceId: panel.id,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.companyNameList = [];
            this.addPanelInfo.lesseeList = [];
            res?.data?.forEach((item) => {
              this.companyNameList.push(item.companyName);
              this.addPanelInfo.lesseeList.push(item.lesseeId);
            });
          });
      this.addPanelVisible = true;
    },
    // 修改/添加面板的方法
    addEidtPanel() {
      if (this.addPanelOrEditPanel == 1) {
        if (this.addPanelInfo.buildingCode == "") {
          this.$vc.toast("所在楼栋必选");
          return;
        } else if (this.addPanelInfo.floor == "") {
          this.$vc.toast("所在楼层必选");
          return;
        }
        this.$fly
            .post(addDevice, this.addPanelInfo, {
              headers: {
                isForm: true,
              },
            })
            .then((res) => {
              if (res.code !== 0) {
                // this.$vc.toast("添加失败");
                return;
              }
              this.addPanelVisible = false;
              this.$vc.toast("添加成功");
              this.queryPanelStart();
              this.addPanelInfo = {
                regionCode: this.$vc.getCurrentRegion().code,
                deviceNo: "",
                type: "",
                buildingCode: "",
                floor: "",
                roomId: "",
                resolution: "",
                comment: "",
                lesseeList: [],
              };
            });
      } else {
        this.$fly
            .post(editDevice, this.addPanelInfo, {
              headers: {
                isForm: true,
              },
            })
            .then((res) => {
              if (res.code != 0) {
                // this.$vc.toast("修改失败");
                return;
              }
              this.addPanelVisible = false;
              this.$vc.toast("修改成功");
              this.queryPanelStart();
              this.addPanelInfo = {
                regionCode: this.$vc.getCurrentRegion().code,
                deviceNo: "",
                type: "",
                buildingCode: "",
                floor: "",
                roomId: "",
                resolution: "",
                comment: "",
                lesseeList: [],
              };
            });
      }
    },
    // 删除面板
    deletePanelBtn(panel) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确认删除吗?",
        onConfirm: () => {
          this.$fly
              .post(deleteDevice, {
                regionCode: panel.regionCode,
                deviceId: panel.id,
              })
              .then((res) => {
                if (res.code != 0) {
                  this.$vc.toast("删除失败");
                  return;
                }
                this.$vc.toast("删除成功");
                this.$CSDialog.instance.closeDialog();
                this.queryPanelStart();
              });
        },
      });
    },
    // 处理面板类型
    processingPanelType(type) {
      let name = "";
      this.PanelType.forEach((item) => {
        if (item.id == type) {
          name = item.name;
        }
      });
      return name;
    },
    // 处理面板分辨率
    processingPanelResolution(id) {
      let name = "";
      this.resolvingPowerList.forEach((item) => {
        if (item.id == id) {
          name = item.name;
        }
      });
      return name;
    },
    // 添加面板组里的添加面板按钮
    PanelsInPanelGroupsBtn() {
      this.addPanelsInPanelGroupsVisible = true;
    },

    // 面板组查询面板数量方法
    queryPanelGroupComment(PanelGroupId) {
      this.$router.push({
        name: "numberOfPanels",
        params: {groupId: PanelGroupId},
      });
    },
    // 面板查看关联租客方法
    queryPanelRelatedTenant(panelId) {
      this.$fly
          .get(queryDeviceLessees, {
            deviceId: panelId,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.panelRelatedTenant = res.data;
            this.panelRelatedTenantVisible = true;
          });
    },
    // 查看面板组备注方法
    lookGroupPanelComment(comment) {
      this.lookGroupPanelCommentInfo = comment;
      this.panelGroupDialogVisible = true;
    },
    // 查看面板备注方法
    lookPanelComment(comment) {
      this.lookPanelCommentInfo = comment;
      this.panelDialogVisible = true;
    },
    // 判断进出的方法
    judgeInAndOut(type) {
      if ([4, 5, ""].includes(type)) {
        return "-";
      } else if ([1, 3, 6].includes(type)) {
        return "进";
      } else if ([2].includes(type)) {
        return "出";
      }
    },
    // 面板组中面板查询方法
  },
};
</script>

<style lang="stylus" scoped>
.filter-panel-input {
  height: 30px;
  width: 180px;
  border: 1px solid #979797;
  border-radius: 4px;
  vertical-align: middle;
  padding-left: 10px;
}

.queryBtn {
  margin: 0 0 0 30px;
  vertical-align: middle;
  padding: 0;
  width: 50px;
  border-radius: 4px;
}

.filter-panel-select {
  width: 150px;
  vertical-align: middle;
}

.notice_box {
  margin-bottom: 23px;
}

.notice_name {
  width: 400px;
  height: 38px;
  font-size: 22px;
  border: 1px solid #999;
  border-radius: 4px;
  padding-left: 10px;
  vertical-align: middle;
}

.notice_title {
  display: inline-block;
  font-size: 22px;
  width: 144px;
  color: #000;
  text-align: right;
  margin-right: 40px;
  vertical-align: middle;
}

.notice_select {
  width: 215px;
  height: 40px;
  font-size: 24px;
  padding-left: 6px;
}

.cs-style .whilt input[type='checkbox']:checked:after {
  content: ' ';
  background-image: url('../../../public/common/static/img/checkbox/checkedWhite.png');
  background-size: 105% 105%;
  background-position: center;
  border: none;
}
</style>
